import React, { useState, useCallback } from 'react'
import { Helmet } from 'react-helmet'

import { Row, Col, Container, Layout } from '../../components/Layout'
import { CardTitle } from '../../components/CardTitle'
import { BodyText1, Heading2, Heading4 } from '../../components/Heading'
import {
  ArrowDownIcon,
  BagIcon,
  TargetIcon,
  TelescopeIcon,
} from '../../components/Icon'
import { Section } from '../../components/Section'
import { Button } from '../../components/Button'
import { BrandCard } from './BrandCard'
import { handleImgUrl } from '../../utils'
import { useI18n, useTranslation } from '../../i18n'
import { usePagination } from '../../utils/pagination'
import { StaticImage } from 'gatsby-plugin-image'
import './index.scss'

interface Portfolio {
  title: string
  head: SectionCard
  strategy: SectionCard
  company: string
  filter: Record<string, string>
  philosophy_text: string
}

interface CompanyTag {
  name: string
}

interface Company {
  logo: ServerImageData
  name: string
  subtitle: string
  description: string
  tag: APIResponse<CompanyTag>
  external_link?: string
}

interface HighlightCompany {
  company: APIResponse<Company>
}

export const BrandItem: React.FC<{ url: string }> = ({ url }) => {
  return <img className={`brand-item`} src={url}></img>
}

interface FilterOption {
  label: string
  value: string
}

interface FilterProps {
  filterOptions: FilterOption[]
  data: APIListResponse<Company>
}

export const DesktopFilters: React.FC<FilterProps> = ({
  filterOptions,
  data,
}) => {
  const [selected, setSelected] = useState('*')
  const { t } = useTranslation({ en: {}, zh: {} })
  const filter = useCallback(
    (data: ResponseData<Company>) => {
      if (selected === '*') {
        return true
      }
      const tag = data.attributes.tag?.data
      if (!tag) {
        return false
      }
      return tag.attributes.name === selected
    },
    [selected]
  )
  const pagination = usePagination(data, { page: 1, pageSize: 21 }, filter)

  return (
    <div className="desktop">
      <div className="selection">
        {filterOptions.map((filter) => {
          return (
            <Button
              key={filter.value}
              border={filter.value === selected}
              onClick={() => {
                setSelected(filter.value)
              }}>
              {filter.label}
            </Button>
          )
        })}
      </div>
      <Row>
        {pagination.list.map((company, index) => {
          return (
            <Col key={company.attributes.name + index.toString()} md={3} lg={3}>
              <BrandCard
                {...company.attributes}
                icon={handleImgUrl(
                  company.attributes.logo.data?.attributes.url
                )}
              />
            </Col>
          )
        })}
        {new Array(3).fill(1).map((key, index) => {
          return (
            <Col
              className="hidden"
              md={3}
              lg={3}
              key={key + index.toString()}></Col>
          )
        })}
      </Row>
      {pagination.hasMore && (
        <Button border className="more" onClick={() => pagination.next()}>
          {t('more')}
        </Button>
      )}
    </div>
  )
}

const MobileFilterItem: React.FC<{
  selected: string
  filter: FilterOption
  onChange: (key: string) => void
  data: APIListResponse<Company>
  enableFilter?: boolean
}> = ({ filter, selected, onChange, data }) => {
  const filterFn = useCallback(
    (data: ResponseData<Company>) => {
      if (selected === '*') {
        return true
      }
      const tag = data.attributes.tag?.data
      if (!tag) {
        return true
      }
      return tag.attributes.name === selected
    },
    [selected]
  )
  const pagination = usePagination(data, { page: 1, pageSize: 3 }, filterFn)

  return (
    <div>
      <Button
        border
        open={selected === filter.value}
        onClick={() => onChange(filter.value === selected ? '' : filter.value)}>
        {filter.label}
      </Button>
      <div style={{ display: selected === filter.value ? 'block' : 'none' }}>
        {pagination.list.map((company) => {
          return (
            <BrandCard
              key={company.id}
              {...company.attributes}
              icon={handleImgUrl(company.attributes.logo.data?.attributes.url)}
            />
          )
        })}
        {pagination.hasMore && (
          <Button border className="mobile-down" onClick={pagination.next}>
            <ArrowDownIcon />
          </Button>
        )}
      </div>
    </div>
  )
}

const MobileFilters: React.FC<{
  filterOptions: FilterOption[]
  data: APIListResponse<Company>
}> = ({ filterOptions, data }) => {
  const [selected, setSelected] = useState('*')

  return (
    <div className="mobile-selection">
      {filterOptions.map((filter) => {
        return (
          <MobileFilterItem
            selected={selected}
            key={filter.value}
            filter={filter}
            onChange={(key) => setSelected(key)}
            data={data}
          />
        )
      })}
    </div>
  )
}

// Add interface for CardTitle props
interface CardTitleProps {
  children: React.ReactNode
}

export const PortfolioPage: React.FC<
  PageData<{
    portfolio: APIResponse<Portfolio>
    companies: APIListResponse<Company>
    companyTags: APIListResponse<CompanyTag>
    highlightCompanies: APIListResponse<HighlightCompany>
  }>
> = ({ pageContext }) => {
  const {
    serverData: { portfolio, companies, companyTags, highlightCompanies },
  } = pageContext

  const { t } = useTranslation({ zh: {}, en: {} })
  const { isChinese } = useI18n()

  const filterOptions: FilterOption[] = [
    { label: t('Filter All'), value: '*' },
  ].concat(
    companyTags.data.map((tag) => {
      return {
        label: tag.attributes.name,
        value: tag.attributes.name,
      }
    })
  )
  // const hightlight = highlightCompanies.data.reduce(
  //   (prev, next) => {
  //     const id = next.attributes.company.data.id
  //     // skip duplicate
  //     if (!prev.map[id]) {
  //       prev.map[id] = true
  //       prev.companies.push(next.attributes.company.data)
  //     }
  //     return prev
  //   },
  //   {
  //     map: {},
  //     companies: [],
  //   } as { map: Record<number, boolean>; companies: ResponseData<Company>[] }
  // )

  // const mergedCompanies = hightlight.companies.concat(
  //   companies.data.filter((company) => !hightlight.map[company.id])
  // )

  return (
    <Layout className="portfolio-page">
      <Helmet>
        <title>{portfolio.data.attributes.title}</title>
      </Helmet>
      <Container className="head">
        <Heading2>{portfolio.data.attributes.head.title}</Heading2>
        <BodyText1>{portfolio.data.attributes.head.description}</BodyText1>
      </Container>
      <Container>
        <Section
          className="strategy"
          title={portfolio.data.attributes.strategy.title}
          icon={<TargetIcon />}
          content={portfolio.data.attributes.strategy.description}></Section>
      </Container>
      <Container className="philosophy">
        <div className="title">
          <CardTitle children={
            <>
              <TelescopeIcon color="orange"></TelescopeIcon>
              <Heading4>{portfolio.data.attributes.philosophy_text}</Heading4>
            </>
          } />
        </div>
      </Container>
      <Container className="quadrant">
        {isChinese ? (
          <StaticImage
            src="./images/bg-quadrant-zh.png"
            alt="Quadrant"
            quality={100}
          />
        ) : (
          <StaticImage
            src="./images/bg-quadrant-en.png"
            alt="Quadrant"
            quality={100}
          />
        )}
      </Container>
      <Container className="company">
        <div className="title">
          <CardTitle children={
            <>
              <BagIcon />
              <Heading4>{portfolio.data.attributes.company}</Heading4>
            </>
          } />
        </div>
        <div className="items">
          {highlightCompanies.data.map((company, index) => {
            return (
              <div key={company.id + index.toString()} className="item-wrapper">
                <BrandItem
                  url={handleImgUrl(
                    company.attributes.company.data.attributes.logo.data
                      .attributes.url
                  )}
                />
              </div>
            )
          })}
          {new Array(5).fill(5).map((key, index) => {
            return (
              <div
                className="item-wrapper hidden"
                key={key + index.toString()}></div>
            )
          })}
        </div>
      </Container>
      <Container className="brand-intros">
        <DesktopFilters data={companies} filterOptions={filterOptions} />
        <MobileFilters
          data={companies}
          filterOptions={filterOptions}></MobileFilters>
      </Container>
    </Layout>
  )
}
